import { useRuntimeConfig } from '#app'
const { useCustomFetch } = useFetchBase()

// request(REQ) Api  --------------------------------
const base = '/api/message'
const modules = '/channels'
const path = base + modules

const getChannels = path // 獲取渠道列表
const addChannel = path // 新增渠道
const editChannel = path + '/{0}' // 修改渠道
const delChannel = path + '/{0}' // 刪除渠道
const searchChannel = path + '/q' // 搜尋渠道

const getChannelsDetails = path + '/url/details?url=' // 獲取渠道細節

// request(REQ) Function  --------------------------------

/**
 * 獲取渠道列表
 * @param {Object} params 查詢參數
 * @param {Object} callback 回調函數
 * @returns
 */
const getChannelsREQFn = (params, callback) => {
  const config = useRuntimeConfig()
  const apiBaseUrl = `${config.public.apiBaseUrl}`
  const api = apiBaseUrl + combineUrlWithParams(getChannels, params)
  return useCustomFetch(api, {
    method: 'GET',
    onResponse: callback
  })
}

/**
 * 新增渠道
 * @param {Object} formData 提交表單
 * @param {Object} callback 回調函數
 * @returns
 */
const addChannelREQFn = (formData, callback) => {
  const config = useRuntimeConfig()
  const apiBaseUrl = `${config.public.apiBaseUrl}`
  return useCustomFetch(apiBaseUrl + addChannel, {
    method: 'POST',
    body: JSON.stringify(formData),
    onResponse: callback
  })
}

/**
 * 修改渠道
 * @param {String} channelId 渠道id
 * @param {Object} formData 提交表單
 * @param {Object} callback 回調函數
 * @returns
 */
const editChannelREQFn = (channelId, formData, callback) => {
  const config = useRuntimeConfig()
  const apiBaseUrl = `${config.public.apiBaseUrl}`
  const api = apiBaseUrl + stringFormat(editChannel, channelId)
  return useCustomFetch(api, {
    method: 'PUT',
    body: JSON.stringify(formData),
    onResponse: callback
  })
}

/**
 * 刪除渠道
 * @param {String} channelId 渠道id
 * @param {Object} callback 回調函數
 * @returns
 */
const delChannelREQFn = (channelId, callback) => {
  const config = useRuntimeConfig()
  const apiBaseUrl = `${config.public.apiBaseUrl}`
  const api = apiBaseUrl + stringFormat(delChannel, channelId)
  return useCustomFetch(api, {
    method: 'DELETE',
    onResponse: callback
  })
}

/**
 * 搜尋渠道
 * @param {Object} params 查詢參數
 * @param {Object} callback 回調函數
 * @returns
 */
const searchChannelFn = (params, callback) => {
  const config = useRuntimeConfig()
  const apiBaseUrl = `${config.public.apiBaseUrl}`
  const api = apiBaseUrl + combineUrlWithParams(searchChannel, params)
  return useCustomFetch(api, {
    method: 'GET',
    onResponse: callback
  })
}

/**
 * 獲取渠道細節
 * @param {String} url channelUrl
 * @param {Object} callback 回調函數
 * @returns
 */
const getChannelsDetailsFn = (url, callback) => {
  const config = useRuntimeConfig()
  const apiBaseUrl = `${config.public.apiBaseUrl}`
  return useCustomFetch(apiBaseUrl + getChannelsDetails + url, {
    method: 'GET',
    onResponse: callback
  })
}

export {
  getChannelsREQFn,
  addChannelREQFn,
  editChannelREQFn,
  delChannelREQFn,
  searchChannelFn,
  getChannelsDetailsFn
}
